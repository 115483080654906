import * as React from 'react';
import PropTypes from 'prop-types';
import { useLocation } from '@reach/router';
import { Link, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import BackgroundImage from 'gatsby-background-image';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { MDXProvider } from '@mdx-js/react';
import { Gallery, Item } from 'react-photoswipe-gallery';

// Components
import Layout from '../../components/layout/Layout';
import ArticleCard from '../../components/card/Article';

// Article
const ArticlePage = ({ pageContext, data }) => {

  const location = useLocation();
  const origin = location.origin;

  const pageImage = { 
    url: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    secureUrl: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid.srcWebp : null,
    type: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.type : null,
    width: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.gatsbyImageData.width : null,
    height: data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.gatsbyImageData.height : null,
    alt: data.article.frontmatter.image.alt ? data.article.frontmatter.image.alt : null
  };

  const pageSchemas = [
    { 
      type: "article",
      title: data.article.frontmatter.meta.title,
      description: data.article.frontmatter.meta.description,
      cover: data.article.frontmatter.image.cover,
      images: data.article.frontmatter.images,
      date_published: data.article.frontmatter.date_published,
      date_modified: data.article.frontmatter.date_modified,
      author_name: data.author ? data.publisher.frontmatter.name : null,
      author_url: data.author ? data.publisher.frontmatter.url : null,
      publisher_name: data.publisher ? data.publisher.frontmatter.name : null,
      publisher_url: data.publisher ? data.publisher.frontmatter.url : null,
      publisher_logo_url: data.publisher ? data.publisher.frontmatter.logo_url : null,
    },
    { 
      type: "breadcrumb",
      listItems: [
        { 
          name: "Articles",
          item: origin + "/articles/"
        },
        { 
          name: data.article.frontmatter.title
        },
      ]
    }
  ];

  const headerBackgroundImage = data.article.frontmatter.image.cover ? data.article.frontmatter.image.cover.childImageSharp.fluid : null;
  const headerBackgroundImageStack = [
    headerBackgroundImage,
    `linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3))`,
  ].reverse()

  return (
    <Layout 
      pageTitle={`${data.article.frontmatter.meta.title} | ${data.site.siteMetadata.title} - L'entreprise spécialiste du bâtiment à Roanne`}
      pageDescription={data.article.frontmatter.meta.description} 
      pageKeywords={data.article.frontmatter.meta.keywords} 
      pageImage={pageImage}
      pageSchemas={pageSchemas}
      headerClassName="header header-layout-type-header-2rows"
    >
      <BackgroundImage
        Tag="section"
        className="page-title divider layer-overlay overlay-dark-8 section-typo-light bg-img-center"
        fluid={headerBackgroundImageStack}
      >
        <div className="container pt-90 pb-90">
          <div className="section-content">
            <div className="row">
              <div className="col-md-12 text-center">
                {data.article.frontmatter.title &&
                  <h2 className="title text-white">{data.article.frontmatter.title}</h2>
                }
                <nav role="navigation" className="breadcrumb-trail breadcrumbs">
                  <div className="breadcrumbs">
                    <span className="trail-item trail-begin">
                      <Link to={`/`}>Accueil</Link>
                    </span>
                    <span><i className="bi bi-chevron-right"></i></span>
                    <span className="trail-item trail-end active">
                      <Link to={`/articles/`}>Articles</Link>
                    </span>
                    {data.article.frontmatter.title &&
                      <span>
                        <span><i className="bi bi-chevron-right"></i></span>
                        <span className="trail-item trail-end active">{data.article.frontmatter.title}</span>
                      </span>
                    }
                  </div>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <section>
        <div className="container pb-60">
          <div className="section-content">
            <div className="row">
              <div className="col-lg-4 col-12 order-first">
                <div className="bg-light media border p-20 mb-20">
                  <div className="mb-20">
                    <GatsbyImage
                      image={getImage(data.publisher.frontmatter.image.card)}
                      alt={data.publisher.frontmatter.image.alt}
                    />
                  </div>
                  <div className="tm-sc tm-sc-unordered-list">
                    <ul className="list-unstyled">
                      {data.publisher.frontmatter.country &&
                        <li key="country">
                          <span><i className="bi bi-geo-alt-fill"> </i> </span>
                          {data.publisher.frontmatter.country}
                        </li>
                      }
                      {data.publisher.frontmatter.website &&
                        <li key="website">
                          <span><i className="bi bi-globe"> </i> </span>
                          <a className="text-theme-colored3 font-weight-300" href={data.publisher.frontmatter.website} target="_blank" rel="noopener noreferrer" aria-label="website">{data.publisher.frontmatter.website}</a>
                        </li>
                      }
                      {data.publisher.frontmatter.email &&
                        <li key="email">
                          <span><i className="bi bi-envelope"> </i> </span>
                          <a className="text-theme-colored3 font-weight-300" href={"mailto:" + data.publisher.frontmatter.email}>{data.publisher.frontmatter.email}</a>
                        </li>
                      }
                      {data.publisher.frontmatter.telephone &&
                        <li key="telephone">
                          <span><i className="bi bi-telephone"></i></span>
                          <a className="text-theme-colored3 font-weight-300" href={"tel:" + data.publisher.frontmatter.telephone}>{data.publisher.frontmatter.telephone} </a>                                
                        </li>
                      }
                    </ul>
                    {data.publisher.frontmatter.social_networks &&
                      <ul className="styled-icons icon-dark icon-rounded icon-theme-colored1">
                        {data.publisher.frontmatter.social_networks.linkedIn &&
                          <li key="linkedin"><a className="social-link" href={data.publisher.frontmatter.social_networks.linkedIn} target="_blank" rel="noopener noreferrer" aria-label="linkedin"><i className="bi bi-linkedin"></i></a></li>
                        }
                        {data.publisher.frontmatter.social_networks.facebook &&
                          <li key="facebook"><a className="social-link" href={data.publisher.frontmatter.social_networks.facebook} target="_blank" rel="noopener noreferrer" aria-label="facebook"><i className="bi bi-facebook"></i></a></li>
                        }
                        {data.publisher.frontmatter.social_networks.instagram &&
                          <li key="instagram"><a className="social-link" href={data.publisher.frontmatter.social_networks.instagram} target="_blank" rel="noopener noreferrer" aria-label="instagram"><i className="bi bi-instagram"></i></a></li>
                        }
                        {data.publisher.frontmatter.social_networks.twitter &&
                          <li key="twitter"><a className="social-link" href={data.publisher.frontmatter.social_networks.twitter} target="_blank" rel="noopener noreferrer" aria-label="twitter"><i className="bi bi-twitter"></i></a></li>
                        }
                      </ul>
                    }
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-12 order-lg-first">
                <MDXProvider
                  components={{
                    h2: props => <div className="mt-20 mb-10"><h2 {...props}>{props.children}</h2></div>,
                    h3: props => <div className="mt-20 mb-10"><h3 {...props}>{props.children}</h3></div>,
                    h4: props => <div className="mt-20 mb-10"><h4 {...props}>{props.children}</h4></div>,
                    h5: props => <div className="mt-20 mb-10"><h5 {...props}>{props.children}</h5></div>,
                    ul: props => <div className="mb-30"><ul {...props}>{props.children}</ul></div>,
                    a: props => <a {...props} className="text-theme-colored1 font-weight-600" target="_blank" rel="noopener noreferrer">{props.children}</a>,
                    blockquote: props => <blockquote {...props} className="tm-sc tm-sc-blockquote blockquote-style1">{props.children}</blockquote>,
                  }}
                >
                  <MDXRenderer
                    localImages={data.article.frontmatter.images}
                  >
                    {data.article.body}
                  </MDXRenderer>
                </MDXProvider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {data.article.frontmatter.galleries && data.article.frontmatter.galleries.length ?
        <section id="gallery" className="bg-silver-light">
          <div className="container">
            <div className="section-title">
              <div className="row mb-40">
                <div className="col-sm-6">
                  <h5 className="tm-sc tm-sc-line-with-text mt-0 mb-0 text-gray line-after-title "> <span className="horizontal-text">Galerie</span> <span className="horizontal-line bg-theme-colored1"></span></h5>
                  <div className="tm-sc section-title section-title-style1 text-left">
                    <div className="title-wrapper">
                      <h2 className="title"> <span>Découvrez les </span> <span className="text-theme-colored1">photos</span></h2>
                      <div className="title-seperator-line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="isotope-layout masonry grid-3 gutter lightgallery-lightbox clearfix">
							  <div className="isotope-item isotope-item-sizer"></div>
                <div className="tm-sc tm-sc-gallery tm-sc-gallery-masonry gallery-style2-simple">
                  <Gallery>
                    {data.article.frontmatter.galleries.map((gallery, index) => (
                      <Item
                        original={gallery.childImageSharp.fluid.srcWebp}
                        thumbnail={gallery.childImageSharp.fluid.srcWebp}
                        width={gallery.childImageSharp.original.width}
                        height={gallery.childImageSharp.original.height}
                      >
                        {({ ref, open }) => (
                          <div className="isotope-item wide helping home water pointer" role="none" ref={ref} onClick={open} onKeyDown={open}>
                            <div className="tm-gallery box-hover-effect">
                              <div className="effect-wrapper">
                                <div className="thumb ">
                                    <GatsbyImage
                                      ref={ref}
                                      onClick={open}
                                      image={getImage(gallery)}
                                      alt={data.article.frontmatter.image.alt}
                                    />
                                  <span role="none" ref={ref} onClick={open} onKeyDown={open}></span>
                                </div>
                                <div className="overlay-shade shade-theme-colored1"></div>
                                <div className="icons-holder icons-holder-middle">
                                  <div className="icons-holder-inner">
                                    <div className="styled-icons icon-dark icon-circled icon-theme-colored2">
                                      <a className="lightgallery-trigger styled-icons-item" ref={ref} onClick={open} onKeyDown={open}>
                                        <i className="bi bi-search"></i>
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Item>
                    ))}
                  </Gallery>
                </div>
              </div>
            </div>
          </div>
        </section>
      : null}
      {data.otherArticles && data.otherArticles.nodes.length ?
        <section id="other-articles">
          <div className="container pt-90">
            <div className="section-title">
              <div className="row justify-content-md-center">
                <div className="col-md-10">
                  <div className="text-center mb-60">
                    <div className="tm-sc tm-sc-section-title section-title section-title-style1 text-center line-bottom-style4-attached-double-lines1">
                      <div className="title-wrapper">
                        <h2 className="title">Nos autres <span className="text-theme-colored1">articles</span></h2>
                        <div className="title-seperator-line"></div>
                        <div className="paragraph">
                          <p>Découvrez tous les autres articles de presse concernant {data.site.siteMetadata.title}.</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content">
              <div className="tm-sc tm-sc-projects projects-style8-current-theme">
                <div className="row">
                  {data.otherArticles.nodes.map(article => (
                    <ArticleCard key={article.id} data={article} cardStyle="1" cardClassName="col-md-6 col-lg-6 col-xl-4 mb-30" />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      : null}
    </Layout>
  )
}

ArticlePage.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    article: PropTypes.shape({
      frontmatter: PropTypes.shape({
        title: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
      }),
      fields: PropTypes.shape({
        slug: PropTypes.string.isRequired,
      }),
    }).isRequired
  }),
}

export const query = graphql`
  query ($id: String!, $publisher: String!) {
    site: site {
      siteMetadata {
        siteUrl
        title
        description
        keywords
        image {
          alt
        }
      }
    }
    article: mdx(id: {eq: $id}, fileAbsolutePath: { regex: "/data/articles/" }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }        
        title
        description
        publisher
        type
        link
        image {
          cover {
            childImageSharp {
              gatsbyImageData
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          credit {
            text
            link
          }
        }
        images {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        galleries {
          childImageSharp {
            original {
              height
              width
            }
            gatsbyImageData(layout: FULL_WIDTH)
            fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        home
        active
        date(formatString: "D MMMM YYYY", locale: "fr")
      }
      body
    }
    publisher: mdx(slug: {eq: $publisher}, fileAbsolutePath: { regex: "/data/publishers/" }) {
      frontmatter {
        meta {
          title
          description
          keywords
        }        
        name
        description
        country
        website
        social_networks {
          linkedIn
          facebook
          instagram
          twitter
        }
        image {
          card {
            childImageSharp {
              gatsbyImageData
              fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          alt
          credit {
            text
            link
          }
        }
        home
        active
        date(formatString: "D MMMM YYYY", locale: "fr")
      }
      body
    }
    otherArticles: allMdx(
      filter: {
        fileAbsolutePath: { regex: "/data/articles/" }, 
        id: {ne: $id},
        frontmatter: { active: { eq: true } }
      },
      sort: {fields: frontmatter___name, order: ASC}
      limit: 6
    ) {
      nodes {
        frontmatter {
          title
          description
          image {
            card {
              childImageSharp {
                gatsbyImageData
                fluid(quality: 65, toFormat: JPG, maxWidth: 1920) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt
            credit {
              text
              link
            }
          }
          home
          active
          date(formatString: "D MMMM YYYY", locale: "fr")
        }
        id
        slug
      }
    }
  }
`

export default ArticlePage
