import * as React from 'react';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const ArticleCard = ({ data, cardStyle, cardClassName }) => {

  return (
    <>
      {cardStyle === "1" &&
        <div key={data.id} className={cardClassName} data-aos="fade-up" data-aos-delay="-50">
          <Link to={`/article/${data.slug}`} aria-label={data.frontmatter.image.alt}>
            <div className="tm-sc tm-sc-blog tm-sc-blog-masonry blog-style1-current-theme h-100 mb-lg-30">
              <article className="post type-post status-publish format-standard has-post-thumbnail  h-100">
                <div className="entry-header">
                  <div className="post-thumb lightgallery-lightbox">
                    <div className="post-thumb-inner">
                      <div className="thumb">
                        <GatsbyImage
                          image={getImage(data.frontmatter.image.card)}
                          alt={data.frontmatter.image.alt}
                        />
                      </div>
                    </div>
                  </div>
                  <Link to={`/article/${data.slug}`} className="link" aria-label={data.frontmatter.image.alt}>
                    <i className="bi bi-search"></i>
                  </Link>
                </div>
                <div className="entry-content">
                  {data.frontmatter.title &&
                    <h5 className="entry-title">{data.frontmatter.title}</h5>
                  }
                  <ul className="entry-meta list-inline"></ul>
                  {data.frontmatter.description &&
                    <div className="post-excerpt">
                      <div className="mascot-post-excerpt">{data.frontmatter.description}</div>
                    </div>
                  }
                  <div className="post-btn-readmore">
                    <Link to={`/article/${data.slug}`}  className="btn btn-plain-text-with-arrow btn-round" aria-label={data.frontmatter.image.alt}>Voir plus</Link>
                  </div>
                  <div className="clearfix"></div>
                </div>
              </article>
            </div>
          </Link>
        </div>
      }
    </>
  )
}

export default ArticleCard